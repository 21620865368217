<script setup>
import Footer from '@/Components/Navigation/Footer.vue';
import Header from '@/Components/Navigation/Header.vue';
import BackToTopButton from '@/Components/BackToTopButton.vue';
import { onMounted,ref } from 'vue';

// backtotop button causes window not defined
// dont mount til mounted so doesnt throw error
const loading = ref(true);
onMounted(() => {
  loading.value = false;
})
</script>
<template>
  <Header />
  <div
    id="main-content"
    class="hero py-16 mb-6 bg-grey-200"
    tabindex="-1"
    aria-label="Search widget"
  >
    <slot name="hero" />
  </div>
  <main class="w-full px-3 max-w-screen-md mx-auto">
    <slot name="content" />
  </main>
  <BackToTopButton v-if="!loading"></BackToTopButton>
  <Footer />
</template>
