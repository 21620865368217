<script setup>
import { ref } from 'vue';
import InputText from '@/Components/Input/Text.vue';
import { onClickOutside } from '@vueuse/core';
import CourseKeywords from '@/Json/autocomplete/course.json';
import ProviderKeywords from '@/Json/autocomplete/provider.json';
import ScholarshipKeywords from '@/Json/autocomplete/scholarship.json';
import CareerKeywords from '@/Json/autocomplete/career.json';


const keyword = defineModel({type: String});
const autocompletions = ref([]);
const autocompleteList = ref(null);
const emit = defineEmits('search');

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  activeTab: {
    type: String,
    required: true,
  }
});

const autocompleteKeyword = (event) => {
  let keywords = [];
  if(props.activeTab == 'courses'){
    keywords = CourseKeywords;
  }
  if(props.activeTab == 'providers'){
    keywords = ProviderKeywords;
  }
  if(props.activeTab == 'scholarships'){
    keywords = ScholarshipKeywords;
  }
  if(props.activeTab == 'careers'){
    keywords = CareerKeywords;
  }
  const term = event.target.value;
  if (autocompletions.value?.length > 0 && (event.code === 'ArrowDown' || event.code === 'ArrowUp')) {
    autocompleteList.value?.firstElementChild.focus();
  }
  if (keyword.value?.length > 2) {
    autocompletions.value = keywords.filter(k => k.label.toLowerCase().includes(term.toLowerCase()));
  } else {
    autocompletions.value = [];
  }
};
const closeAutocompletions = () => autocompletions.value = [];
const selectAutocompletion = (autocompleteOption) => {
  emit('autocompleteSelected', autocompleteOption);
  closeAutocompletions();
};
const nextAutocompletion = (el) => el.target.nextElementSibling?.focus();
const previousAutocompletion = (el) => el.target.previousElementSibling?.focus();

const showAutocomplete = ref(false);

const keywordElement = ref(null);

onClickOutside(keywordElement, () => {
  showAutocomplete.value = false;
});
</script>


<template>
  <div class="relative">
    <div class="relative flex">
      <InputText
        ref="keywordElement"
        v-model="keyword"
        class="w-full"
        label="Keyword"
        :hide-label="true"
        autocomplete="off"
        name="query"
        :placeholder="placeholder"
        @click="showAutocomplete = true"
        @keyup="autocompleteKeyword"
        @keydown:enter="emit('search')"
      />
    </div>
    <div
      v-if="autocompletions?.length > 0 && showAutocomplete"
      class="border border-solid border-primary absolute rounded-md z-50 bg-white py-2"
    >
      <ul ref="autocompleteList">
        <li
          v-for="(option, optionIndex) in autocompletions"
          :key="`autocomplete-option-${optionIndex}`"
          class="p-2 mb-1 cursor-pointer hover:bg-primary hover:text-white transition-colors duration-100"
          tabindex="-1"
          @click="() => selectAutocompletion(option)"
          @keydown.down="nextAutocompletion"
          @keydown.up="previousAutocompletion"
          @keydown.enter="() => selectAutocompletion(option)"
          @keydown.esc="closeAutocompletions"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>