<script setup>
import { ref } from 'vue';
const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  ariaLabel: {
    type: String,
    required: false
  },
  id: {
    type: String,
    required: true,
  },
  tabIndex: {
    type: Number,
    default: 0
  }
  });

  const activeClass = ref("after:bg-primary-600 text-primary-600");
</script>

<template>
  <li
    class="my-1 cursor-pointer after:content-[''] after:h-1 after:w-full after:rounded-3xl after:block after:mt-1"
    :class="[active ? activeClass : '']"
  >
    <a
      :id="id"
      :tabindex="tabIndex"
      :ariaLabel="ariaLabel"
      href="#"
      class="border-none text-base md:text-2xl pointer-events-none"
      role="tab"
      :aria-selected="active"
    ><slot /></a>
  </li>
</template>
