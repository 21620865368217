<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import Combobox from '@/Components/Input/Combobox.vue';
import Button from '@/Components/Button.vue';
import Icon from '@/Components/Icon/index.vue';
import courseOptions from '@/Json/filters/course.json';
import providerOptions from '@/Json/filters/provider.json';
import scholarshipOptions from '@/Json/filters/scholarship.json';
import careerOptions from '@/Json/filters/career.json';
import { useWindowSize } from '@vueuse/core';

const searchInput = defineModel({type: Object});
const props = defineProps({
  activeTab: {
    type: String,
    required: true,
  }
});

const courseFilters = computed(() => {
  let filters = [
{
    name: 'area_of_study',
    label: 'Areas of study',
    options: courseOptions['area_of_study'],
    multiSelect: true,
  },
  {
    name: 'level_of_study',
    label: 'Level of study',
    options: courseOptions['level_of_study'],
    multiSelect: true,
  },
  {
    name: 'provider_type',
    label: 'Provider type',
    options: courseOptions['provider_type'],
    multiSelect: true,
  },
  {
    name: 'location',
    label: 'Location',
    options: courseOptions['location'],
    multiSelect: true,
  },
  {
    name: 'modes',
    label: 'Study mode',
    options: courseOptions['mode'],
    multiSelect: true,
  },
  {
    name: 'tuition_fee',
    label: 'Estimated total cost(AUD)',
    options: courseOptions['tuition_fee'],
    multiSelect: false,
  },
];
if(searchInput.value["location"].includes("not_in_australia")){
  filters = [...filters,
    {
      name: "countries",
      label: "Countries",
      options: courseOptions['countries'],
      multiSelect: true,
    },]
}

return filters;

});


const providerFilters = computed(() => {
  let filters = [
    {
      name: "provider_type",
      label: "Provider Type",
      options: providerOptions['provider_type'],
      multiSelect: true,
    },
    {
      name: "location",
      label: "Location",
      options: providerOptions['location'],
      multiSelect: true,
    },
  ];
  if(searchInput.value["provider_type"].includes("primary_school") || searchInput.value["provider_type"].includes("high_school") || searchInput.value["provider_type"].includes("combined_school")){
    filters = [...filters, 
    {
      name: "school_sectors",
      label: "Sector",
      options: providerOptions['school_sectors'],
      multiSelect: true,
    },
    {
      name: "genders",
      label: "Gender",
      options: providerOptions['genders'],
      multiSelect: true,
    },
  ];
  }

  return filters;
});
const scholarshipFilters = [
{
    name: "area_of_study",
    label: "Areas of Study",
    options: scholarshipOptions['area_of_study'],
    multiSelect: true,
  },
  {
    name: "level_of_study",
    label: "Level of Study",
    options: scholarshipOptions['level_of_study'],
    multiSelect: true,
  },
  // Temporarily remove scholarship nationality filter while results are not intuitive.
  // {
  //   name: "nationality",
  //   label: "Nationality",
  //   options: scholarshipOptions['nationality'],
  //   multiSelect: true,
  // },
];

const careerFilters = [
  {
    name: "industry",
    label: "Industry",
    options: careerOptions['industry'],
    multiSelect: true,
  },
];

const activeFilters = computed(() => {
  if('courses' == props.activeTab) {
    return courseFilters.value;
  }
  if('providers' == props.activeTab) {
    return providerFilters.value;
  }
  if('scholarships' == props.activeTab) {
    return scholarshipFilters;
  }
  if('careers' == props.activeTab) {
    return careerFilters;
  }
  return [];
});

const emit = defineEmits('clear');

const clearAll = () => {
  emit('clear');
};

const comboElements = ref([]);
const showFilters = ref(true)

const toggleFilters = () => {
  showFilters.value = !showFilters.value;
}

const { width } = useWindowSize();
onMounted(() => {
  if(width.value < 768) {
    showFilters.value = false
  }
})

watch(searchInput.value.location, (value) => {
  if(!value.includes("")){
    searchInput.value.countries = []
  }
})
</script>

<template>
  <div>
    <div class="flex gap-6 items-center my-3 md:my-1">
      <Button class="underline flex" variant="no-style" @click="toggleFilters" >
        {{ showFilters ? 'Less' : 'More' }} options 
        <Icon
          :class="{'scale-y-[-1]': showFilters}"
          class="transition ease-out duration-150 pointer-events-none ms-2"
          path="ChevronDown"
          :height="20"
          :width="20"
        />
      </Button>
      <Button
          v-if="!showFilters"
          variant="no-style"
          @click="clearAll"
        >
          Clear all
        </Button>
    </div>
    <div v-if="showFilters" class="md:flex flex-wrap items-end max-w-screen-lg">
      <div
        v-for="filter in activeFilters"
        :key="`search-filter-${filter.name}`"
        class="md:me-3 mb-3"
      >
        <Combobox
          ref="comboElements"
          v-model="searchInput[filter.name]"
          :label="filter.label"
          :name="filter.name"
          :options="filter.options"
          :multi-select="filter.multiSelect"
          :hide-label="true"
        />
      </div>
      <Button
        variant="no-style"
        class="me-3 mb-3 block md-flex"
        @click="clearAll"
      >
        Clear all
      </Button>
    </div>
    </div>
</template>