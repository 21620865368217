<script setup>
import Tab from '@/Components/Tab/index.vue';
import Button from '@/Components/Button.vue';
import Icon from '@/Components/Icon/index.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  }
});

const activeTab = defineModel({type: String});

const emit = defineEmits(['update']);

const setTab = (value) => {
  activeTab.value = value;
  modalOpen.value = false
  emit('update');
};

const modalOpen = ref(false);
const modalWindow = ref(null);

onClickOutside(modalWindow, (event) => {
  modalOpen.value = false;
}, );

let activeTabIndex = props.tabs.findIndex((e) => e.value == activeTab.value);
const focusTab = (dir) => {
    if(activeTabIndex == 0 && dir == -1) {
      return
    }
    if(activeTabIndex == props.tabs.length - 1 && dir == 1) {
      return
    }

    activeTabIndex += dir;
    document.getElementById(`tab-${props.tabs[activeTabIndex].value}`).focus()
    activeTab.value = props.tabs[activeTabIndex].value
}

</script>

<template>
  <div>
    <ul class="hidden md:flex list-none flex-wrap" tabindex="-1">
      <Tab 
        v-for="tab in tabs" 
        :id="`tab-${tab.value}`"
        :key="`tab-${tab.value}`"
        :tab-index="activeTab == tab.value ? 0 : -1"
        :aria-label="`Start ${tab.value} search`" 
        :active="activeTab == tab.value"
        class="me-6"
        @keydown.left="focusTab(-1)"
        @keydown.right="focusTab(1)"
        @click="setTab(tab.value)"
      >
        {{ tab.label }}
      </Tab>
    </ul>
    <Button variant="link" class="flex md:hidden items-center text-primary-600" @click="modalOpen = !modalOpen" >{{ activeTab.charAt(0).toUpperCase() + activeTab.slice(1) }} <Icon path="ChevronDown" :height="16" :width="16"/></Button>
    <div class="fixed items-center justify-center inset-0 bg-modal-background overflow-y-auto z-[99999] h-full w-full" :class="[modalOpen ? 'flex' : 'hidden']">
      <div ref="modalWindow" class="bg-white w-fit p-6 rounded-md">
        <Button variant="no-style" class="flex justify-end ms-auto" @click="modalOpen = false" >
          <Icon path="Close" :height="24" :width="24" />
        </Button>
        <ul>
          <li v-for="tab in tabs"  :key="`tab-${tab.value}`" class="text-xl mb-3 text-center">
            <Button variant="link" @click="setTab(tab.value)">
              {{ tab.label }}
            </Button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
