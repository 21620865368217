<script setup>
import SearchKeyword from '@/Components/Search/Keyword.vue';
import TabList from '@/Components/Tab/TabList.vue';
import Heading from '@/Components/Heading.vue';
import SearchFilters from '@/Components/Search/Filters.vue';
import { ref, computed, onMounted, nextTick } from 'vue';
import { router } from '@inertiajs/vue3';
import Button from '@/Components/Button.vue';

const activeTab = defineModel({type: String});

const placeholderOptions = {
  courses: 'Enter course search keywords...(Eg. Business Management)',
  providers: 'Enter name of University, College or School',
  scholarships: 'Enter name of scholarship or field of study',
  careers: 'Enter name of career',
};
const keywordPlaceholder = computed(() => {
  return placeholderOptions[activeTab.value] || 'Search by keywords';
});

const props = defineProps({
  queryObject: {
    type: Object,
    required: true,
  }
})

const searchInput = ref({
  query: '',
  provider_type: [],
  location: [],
  countries: [],
  modes: [],
  tuition_fee: '',
  area_of_study: [],
  level_of_study: [],
  nationality: [],
  school_sectors: [],
  genders: [],
  industry: []
});

const tabs = ref([

{
    label: 'Careers',
    value: 'careers',
  },
  {
    label: 'Courses',
    value: 'courses',
  },
  {
    label: 'Providers',
    value: 'providers',
  },
  {
    label: 'Scholarships',
    value: 'scholarships',
  },
]);
const filtersElement = ref(null);

const setSearchInput = () => {
  for (const [key, value] of Object.entries(props.queryObject)) {
    if(typeof searchInput.value[key] == 'string') {
      searchInput.value[key] = decodeURIComponent(value)
    }
    if(typeof searchInput.value[key] == 'object') {
      const items = decodeURIComponent(value).split(',');
      searchInput.value[key] = items
    }
  }
};
onMounted(() => {
  setSearchInput()
});

const resetFilters = () => {
  searchInput.value.query = '';
  searchInput.value.provider_type = [];
  searchInput.value.location = [];
  searchInput.value.countries = [];
  searchInput.value.modes = [];
  searchInput.value.tuition_fee = '';
  searchInput.value.area_of_study = [];
  searchInput.value.level_of_study = [];
  searchInput.value.nationality = [];
  searchInput.value.school_sectors = [];
  searchInput.value.genders = [];
  searchInput.value.industry = [];
};

const search = () => {
  let query = "page=1";
  for (const [key, value] of Object.entries(searchInput.value)) {
    if((typeof value === "object" && value.length) || (typeof value === "string" && value != "")){
      query += `&${key}=${encodeURIComponent(value)}`;
    }
  }

  router.get(`/${activeTab.value}?${query}`, {}, {
    preserveScroll: true
  });
};

const clearResults = () => {
  resetFilters();
  nextTick(search);
};

const setQuery = (option) => {
  searchInput.value.query = option.label;
};
</script>

<template>
  <section>
    <div class="max-w-screen-lg container">
      <Heading
        size="h1"
        style-size="h1"
        class="mb-6 font-display-light text-primary-600"
      >
        Search results
      </Heading>
      <TabList
        v-model="activeTab"
        class="mb-3"
        :tabs="tabs"
        @update="resetFilters"
      />
      <div class=" grid grid-cols-1 md:grid-cols-6 gap-2">
        <div class="col-span-1 md:col-span-5">
            <SearchKeyword
              v-model="searchInput.query"
              class="flex-1 mb-3"
              :placeholder="keywordPlaceholder"
              :active-tab="activeTab"
              @autocomplete-selected="setQuery"
              @search="search"
            />
          <SearchFilters
            ref="filtersElement"
            v-model="searchInput"
            :active-tab="activeTab"
            @clear="clearResults"
          />
        </div>
        <div class="col-span-1">
          <Button
            class="w-full md:w-fit"
            variant="primary"
            @click="search"
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>
